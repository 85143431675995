import React, { useEffect, useState, useRef } from 'react'
import { Layout, Typography, Row, Col, Button, Modal, Form, Input, Select, Popconfirm } from 'antd'
import { useDispatch } from 'react-redux'
import Module from '../../../Components/Module'
import api from '../../../Services/api'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import ChatBox from '../../../Components/ChatBox'

import socket from '../../../Services/socket'
import { UploadOutlined, WarningFilled, CheckCircleFilled } from '@ant-design/icons'

const { Title, Text } = Typography

const { Option } = Select

Number.prototype.toCurrencyString = function () {
  return this.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

String.prototype.toCurrencyString = function () {
  return Number.parseFloat(this)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

// eslint-disable-next-line no-undef
const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3100' : 'https://api.spiry.ro'

const TradePage = () => {
  const params = useParams()

  const { trade_hash } = params

  const componentIsMounted = useRef(true)

  // DATA
  const [chat, setChat] = useState([])
  const [trade, setTrade] = useState({})

  // MODALS
  const [uploadModal, setUploadModal] = useState(false)
  const [disputeModal, setDisputeModal] = useState(false)

  // INPUTS
  const [uploadData, setUploadData] = useState({ })
  const [disputeData, setDisputeData] = useState({ })

  // LOADING
  const [uploading, setUploading] = useState(false)
  const [startingDispute, setStartingDispute] = useState(false)

  const handleUploadSubmit = async () => {
    setUploading(true)

    const success = await api.post('/trade/chat/attachment', { trade_hash: trade.trade_hash, file: uploadData.url })
    
    setUploading(false)

    if (success) {
      setUploadModal(false)
    }
  }

  const handleUploadChange = (e) => {
    const aux = { ...uploadData }
    aux[e.target.name] = e.target.value
    setUploadData(aux)
  }

  const handleDisputeSubmit = async () => {
    setStartingDispute(true)

    const success = await api.post('/trade/dispute', { trade_hash: trade.trade_hash })

    setStartingDispute(false)

    if (success) {
      setDisputeModal(false)
    }
  }

  const handleDisputeChange = (e) => {
    const aux = { ...disputeData }
    aux[e.target.name] = e.target.value
    setDisputeData(aux)
  }

  const handleReleaseBitcoins = () => {
    api.post('/trade/releasebtc', { trade_hash: trade.trade_hash })
  }

  const dispatch = useDispatch()

  const setTrades = (trades) => {
    dispatch({ type: 'CHANGE_OPEN_TRADES_DATA', OpenTrades: trades })
  }

  const refresh = async () => {
    if (!componentIsMounted.current) return

    const trades = await api.get('/trades', { params: { ended: false } })

    setTrades(trades.data)

    const currentTrade = await api.get(`/trade/info/${trade_hash}`)

    setTrade(currentTrade.data)

    const chat = await api.get(`/trade/chat/${trade_hash}`)

    setChat(chat.data)

    setTimeout(refresh, 5000)
  }

  useEffect(() => {
    refresh()

    socket.on('event', (event) => {
      if (!componentIsMounted.current) return

      const { content } = event

      if (!content) return

      if (content.type === 'trade.chat.message' || content.type === 'trade.chat.attachment') {
        refresh()
      }
    })

    return function cleanup() {
      componentIsMounted.current = false
    }
  }, [])

  return (
    <Layout>

      <Modal
        title='Upload image'
        visible={uploadModal}
        onOk={handleUploadSubmit}
        onCancel={() => setUploadModal(false)}
        okButtonProps={{
          loading: uploading
        }}>
        <Form>
          <Form.Item label='Image URL'>
            <Input value={uploadData.url} name='url' onChange={handleUploadChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title='Start dispute'
        visible={disputeModal}
        onOk={handleDisputeSubmit}
        onCancel={() => setDisputeModal(false)}
        okButtonProps={{
          loading: startingDispute
        }}>
        <Form>
          <Form.Item label='Reason'>
            <Select value={disputeData.reason_type} onChange={(value) => handleDisputeChange({ target: { name: 'reason_type', value } })}>
              <Option value='vendor_coinlocker'>Coin locker</Option>
              <Option value='vendor_payment_issue'>Payment issue</Option>
              <Option value='vendor_other'>Other</Option>
            </Select>
          </Form.Item>
          <Form.Item label='Description'>
            <Input value={disputeData.reason} name='reason' onChange={handleDisputeChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Module title={<Title level={4}>Battle station</Title>}>
        <Row>
          <Col md={12}>
            <Module
              className='no-padding'
              noPadding
              bordered
              loading={!chat.messages}
              title={<Title style={{ marginBottom: 0 }} level={3}>Trade chat</Title>}
            >
              <ChatBox
                handleRefresh={refresh}
                trade={trade}
                chat={chat.messages ? chat.messages : []}
              />
            </Module>
          </Col>
          <Col md={12}>

            <Module loading={!trade.trade_hash} title={<Title style={{ marginBottom: 0 }} level={3}>Actions</Title>}>
              <br/>
              {trade.trade_hash && (
                <div>
                  <Button 
                    onClick={() => setUploadModal(true)}
                    disabled={trade.ended} 
                    type='default' 
                    style={{ backgroundColor: '#2f89d9', borderColor: '#2f89d9', color: 'white' }}
                  >
                    <UploadOutlined/>
                    Upload image
                  </Button>
                  <Button 
                    onClick={() => setDisputeModal(true)}
                    disabled={trade.ended || trade.trade_status === 'Dispute open'} 
                    type='danger'
                  >
                    <WarningFilled/>
                    Start dispute
                  </Button>
                  <Popconfirm
                    title='Are you sure you want to release bitcoins?'
                    onConfirm={handleReleaseBitcoins}
                    okText='Yes'
                    cancelText='No'
                    disabled={trade.ended || trade.trade_status === 'Dispute open'}
                  >
                    <Button 
                      disabled={trade.ended || trade.trade_status === 'Dispute open'} 
                      type='primary'
                      style={{ marginBottom: '10px' }}
                    >
                      <CheckCircleFilled/>
                      Release bitcoins
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Module>

            <Module loading={!trade.trade_hash}>
              {trade.trade_hash && (
                <div className='trade-info'>
                  <Title style={{ marginBottom: 0 }} level={3}>Trade info</Title>
                  <br/>
                  <Text className='card-title'>
                    Trade hash: 
                    <span>{trade.trade_hash}</span>
                  </Text>
                  <Text className='card-title'>
                    Customer: <span>{trade.responder_username}</span>
                  </Text>
                  <Text className='card-title'>
                    Offer hash: 
                    <span>{trade.offer_hash}</span>
                  </Text>
                  <Text className='card-title'>
                    Payment method: 
                    <span>{trade.payment_method_name}</span>
                  </Text>
                  <Text className='card-title'>
                    Status: 
                    <span>{trade.ended ? 'Ended' : trade.trade_status}</span>
                  </Text>
                  <Text className='card-title'>
                    Amount ({trade.fiat_currency_code}):{' '}
                    <span>{trade.fiat_amount_requested.toCurrencyString()}</span>
                  </Text>
                  <Text className='card-title'>
                    Amount (BTC): 
                    <span>{Number.parseInt(trade.crypto_amount_requested) / (100000000)}</span>
                  </Text>
                  <Text className='card-title'>
                    Margin: 
                    <span>{trade.margin}</span>
                  </Text>
                  <Text className='card-title'>
                    Started at:
                    <span>{moment(trade.started_at).format('DD/MM/YYYY - HH:mm')} (UTC
                    Time)</span>
                  </Text>
                </div>
              )}
            </Module>
        
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Module 
              bordered 
              loading={!chat.attachments}
              title={
                <Text className='card-title'>
                  Attachments (
                  {chat.attachments ? chat.attachments.length : '0'})
                </Text>
              }>
              <div className="attachments">
                {chat.attachments &&
                  chat.attachments.map((item, index) => (
                    <div key={index} className='attachment'>
                      <img
                        className='attachment-img'
                        src={`${API_URL}/trade/attachment/${(item.image_hash)}`}
                      />
                    </div>)
                  )}
              </div>
            </Module>
          </Col>
        </Row>

      </Module>
    </Layout>
  )
}

export default TradePage
