import React, { useEffect, useState } from 'react'
import { Layout, Table, Button, Form, Typography, notification, Popconfirm, Modal, Input, Select, Switch } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import Module from '../../../Components/Module'
import api from '../../../Services/api'

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

const { Title } = Typography
const { Option } = Select

const useForceUpdate = () => {
  const [, setIt] = useState(false)
  return () => setIt(it => !it)
}

const OfferGroupsPage = () => {

  const forceUpdate = useForceUpdate()

  const Offers = useSelector(state => state.Offers)

  const [offerGroups, setOfferGroups] = useState([])

  // CREATE GROUPS
  const [createModal, setCreateModal] = useState(false)
  const [createData, setCreateData] = useState({})
  const [creating, setCreating] = useState(false)

  // EDIT GROUPS
  const [editModal, setEditModal] = useState(false)
  const [editData, setEditData] = useState({})
  const [editting, setEditting] = useState(false)
  
  const dispatch = useDispatch()

  const setOffers = (offers) => {
    dispatch({ type: 'CHANGE_OFFERS_DATA', Offers: offers })
  }

  const handleEditChange = ({ name, value }) => {
    const EditingGroup = editData
    EditingGroup[name] = value
    setEditData(EditingGroup)
    forceUpdate()
  }

  const handleEdit = () => {
    setEditting(true)

    api.put('/offers/group', editData)
      .then(() => {
        getData()
        notification.success({
          message: 'Offer group updated successfully'
        })
      })
      .finally(() => {
        setEditModal(false)
        setEditData({ })
        setEditting(false)
      })
  }

  const getData = () => {
    api.get('/offers')
      .then(response => response.data)
      .then(setOffers)

    api.get('/offers/groups')
      .then(response => response.data)
      .then(setOfferGroups)
  }

  const handleDelete = (id) => {
    api.delete(`/offers/group/${id}`)
      .then(() => {
        notification.success({
          message: 'Offer group deleted succesfully'
        })
        getData()
      })
  }
  
  const handleEditModal = (userData) => {
    setEditData(userData)
    setEditModal(true)
  }

  const handleCreate = () => {
    setCreating(true)
    api.post('/offers/group', createData)
      .then(() => {
        getData()
        notification.success({
          message: 'Offer group created successfully'
        })
      })
      .finally(() => {
        setCreateModal(false)
        setCreating(false)
      })
  }

  const handleCreateChange = ({ name, value }) => {
    const data = createData

    data[name] = value

    setCreateData(data)
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Offers hashes',
      dataIndex: 'offers',
      key: 'offers',
      render: offers => <span>{offers.length}</span>
    },
    {
      title: 'Watson enabled',
      dataIndex: 'watson_enabled',
      key: 'watson_enabled',
      render: watson_enabled => <span align="center">{watson_enabled ? <CheckCircleFilled /> : <CloseCircleFilled />}</span>
    },
    {
      title: 'Static messages enabled',
      dataIndex: 'static_message_enabled',
      key: 'static_message_enabled',
      render: static_message_enabled => <span align="center">{static_message_enabled ? <CheckCircleFilled /> : <CloseCircleFilled />}</span>
    },
    {
      title: 'Processing fee',
      dataIndex: 'fee_percentage',
      key: 'fee_percentage',
      render: fee_percentage => <span>{fee_percentage}%</span>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_text, record) => (
        <span>
          <a onClick={() => handleEditModal(record)} style={{ marginRight: 16 }}>Edit</a>
          <Popconfirm
            title='Are you sure you want to delete this offer group?'
            onConfirm={() => handleDelete(record._id)}
            okText='Yes'
            cancelText='No'
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    }
  ]
  
  return (
    <Layout>
      <Module>
        <Modal
          title='Create offer group'
          visible={createModal}
          onOk={handleCreate}
          onCancel={() => setCreateModal(false)}
          okButtonProps={{
            loading: creating
          }}>
          <Form>
            <Form.Item label='Name'>
              <Input onChange={(e) => handleCreateChange({ name: 'name', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Processing fee'>
              <Input type='number' onChange={(e) => handleCreateChange({ name: 'fee_percentage', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Offers'>
              <Select mode='multiple' onChange={(value) => handleCreateChange({ name: 'offers', value })}>
                {Offers && Offers.map(offer => (
                  <Option key={offer.offer_hash} value={offer.offer_hash}>{offer.offer_hash}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Watson enabled'>
              <Switch size='default' onChange={(enabled) => handleCreateChange({ name: 'watson_enabled', value: enabled })} />
            </Form.Item>
            <Form.Item label='Watson assistant ID'>
              <Input type='text' onChange={(e) => handleCreateChange({ name: 'watson_assistant', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Static message enabled'>
              <Switch size='default' onChange={(enabled) => handleCreateChange({ name: 'static_message_enabled', value: enabled })} />
            </Form.Item>
            <Form.Item label='Paxful chat static message'>
              <Input type='text' onChange={(e) => handleCreateChange({ name: 'static_message', value: e.target.value })} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title='Edit offer group'
          visible={editModal}
          onOk={handleEdit}
          onCancel={() => setEditModal(false)}
          okButtonProps={{
            loading: editting
          }}>
          <Form>
            <Form.Item label='Name'>
              <Input value={editData.name} onChange={(e) => handleEditChange({ name: 'name', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Processing fee'>
              <Input value={editData.fee_percentage} type='number' onChange={(e) => handleEditChange({ name: 'fee_percentage', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Offers'>
              <Select value={editData.offers} mode='multiple' onChange={(value) => handleEditChange({ name: 'offers', value })}>
                {Offers && Offers.map(offer => (
                  <Option key={offer.offer_hash} value={offer.offer_hash}>{offer.offer_hash}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Watson enabled'>
              <Switch checked={editData.watson_enabled} size='default' onChange={(enabled) => handleEditChange({ name: 'watson_enabled', value: enabled })} />
            </Form.Item>
            <Form.Item label='Watson assistant ID'>
              <Input value={editData.watson_assistant} type='text' onChange={(e) => handleEditChange({ name: 'watson_assistant', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Static message enabled'>
              <Switch size='default' onChange={(enabled) => handleEditChange({ name: 'static_message_enabled', value: enabled })} />
            </Form.Item>
            <Form.Item label='Paxful chat static message'>
              <Input value={editData.static_message} type='text' onChange={(e) => handleEditChange({ name: 'static_message', value: e.target.value })} />
            </Form.Item>
          </Form>
        </Modal>

        <Title level={4}>Actions</Title>

        <Button onClick={() => setCreateModal(true)} type='primary'>Create offer group</Button> 
      </Module>

      <Module title={<Title level={4}>Offer groups</Title>}>
        <Table bodyStyle={{backgroundColor: '#ececec'}} columns={columns} dataSource={offerGroups} loading={offerGroups.length === 0} />
      </Module>
    </Layout>
  )
}

export default OfferGroupsPage