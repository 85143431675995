import React from 'react'
import Module from './Module'
import { Alert, Input, Row, Col, Button } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SendOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'

import api from '../Services/api'

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace)
}

function Message(props) {

  const { trade, message } = props

  if (!trade || !message) return null

  const BUYER_MESSAGE = {
    type: 'warning',
    style: { justifyContent: 'flex-start' }
  }
  
  const SELLER_MESSAGE = {
    type: 'success',
    style: { justifyContent: 'flex-end' }
  }

  const INFO = {
    type: 'info',
    showIcon: true,
    style: { justifyContent: 'center' }
  }

  const DISPUTE = {
    ...INFO,
    type: 'error'
  }

  const DISPUTE_DISCLAMER = {
    ...INFO,
    type: 'warning'
  }

  var styling = { }

  if (message.type === 'msg') {
    switch (message.author) {
      case trade.owner_username:
        styling = SELLER_MESSAGE
        break
      case trade.responder_username:
        styling = BUYER_MESSAGE
        break
      default:
        styling = INFO
    }
  } else if (message.type === 'trade_dispute_started') {
    styling = DISPUTE
  } else if (message.type === 'dispute_disclaimer_message') {
    styling = DISPUTE_DISCLAMER
  } else {
    styling = INFO
  }

  const date = new Date(message.timestamp * 1000)

  message.text = replaceAll(message.text, '\n', '<br/>')

  const body = (
    <div>
      <span dangerouslySetInnerHTML={{__html: message.text}} />
      <br />
      <span style={{ paddingTop: '10px', float: 'right' }}>
        <ClockCircleOutlined style={{ marginRight: '5px' }}/>
        {moment(date).format('HH:mm')}
      </span>
    </div>
  )

  return (
    <div className='chat-message' style={styling.style}>
      <Alert
        showIcon={styling.showIcon} 
        type={styling.type} 
        message={styling.showIcon ? 'Paxful info' : body}
        description={styling.showIcon ? body : ''}
      />
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  trade: PropTypes.object.isRequired,
}

function ChatBox({ chat, trade, handleRefresh }) {

  const [input, setInput] = useState('')
  const [sending, setSending] = useState(false)

  const handleSubmit = async () => {
    if (input.length === 0) return

    setSending(true)

    const body = {
      trade_hash: trade.trade_hash,
      message: input
    }
      
    await api.post('/trade/chat/message', body)

    setInput('')
    setSending(false)
    handleRefresh()
  }

  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  return (
    <div>
      <Module className='chat-box'>
        {chat.map(message => 
          <Message trade={trade} key={message.id} message={message} />
        )}
      </Module>
      <hr/>
      <div>
        <Row>
          <Col md={20}>
            <Input 
              disabled={sending}
              value={input}
              onChange={handleInputChange}
              onPressEnter={handleSubmit}
              placeholder='Insert text here'
              type='text'
            />
          </Col>
          <Col md={4} className='chat-button'>
            <Button type='primary' onClick={handleSubmit}>
              <SendOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ChatBox