import React, { useEffect } from 'react'
import { Layout, Typography, Row, Col, Button, Icon } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import Module from '../../../Components/Module'
import api from '../../../Services/api'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const { Title, Paragraph, Text } = Typography

const BattleTradesPage = () => {

  const OpenTrades = useSelector(state => state.OpenTrades)

  const dispatch = useDispatch()

  const history = useHistory()

  const setTrades = (trades) => {
    dispatch({ type: 'CHANGE_OPEN_TRADES_DATA', OpenTrades: trades })
  }

  const refresh = async () => {
    const trades = await api.get('/trades', { params: { ended: false } })
    
    setTrades(trades.data)

    setTimeout(refresh, 5000)
  }
  
  const handleRedirect = (trade_hash) => {
    return history.push(`/battle/trades/${trade_hash}`)
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Layout>
      <Module title={<Title level={4}>Open trades</Title>}>
        {OpenTrades.map(trade => (<Module bordered key={trade.trade_hash} title={<Text className='card-title'>{trade.trade_hash}</Text>}>
          <Row>
            <Col md={12}>
              <Paragraph>Status: {trade.trade_status}</Paragraph>
              <Paragraph>Amount: $ {trade.fiat_amount_requested}</Paragraph>
              <Paragraph>Margin: {trade.margin}</Paragraph>
              <Paragraph>Started at: {moment(trade.started_at).format('DD/MM/YYYY - HH:mm')}</Paragraph>
            </Col>
            <Col offset={6} md={6}>
              <Button type='primary' size='large' onClick={handleRedirect.bind(this, trade.trade_hash)}>
                <Icon type="login"/>
                Enter battle station
              </Button>
            </Col>
          </Row>
        </Module>))}
      </Module>
    </Layout>
  )
}

export default BattleTradesPage