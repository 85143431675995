import { createStore, combineReducers } from 'redux'

import User from './Reducers/User'
import EditingUser from './Reducers/EditingUser'
import Currencies from './Reducers/Currencies'
import Trades from './Reducers/Trades'
import Offers from './Reducers/Offers'
import OpenTrades from './Reducers/OpenTrades'
import Settings from './Reducers/Settings'
import Reports from './Reducers/Reports'

const rootReducer = combineReducers({ User, Currencies, Trades, Offers, OpenTrades, Settings, EditingUser, Reports })

export default createStore(rootReducer)