import axios from 'axios'
import { notification } from 'antd'

function getToken () {
  const jwt = localStorage.getItem('jwt-token')
  return jwt ? 'Bearer ' + jwt : false
}

const api = axios.create({
  // eslint-disable-next-line
  baseURL: (process.env.NODE_ENV === 'development' ? 'http://localhost:3100' : 'https://api.spiry.ro'),
  responseType: 'json',
  transformResponse: (response) => {
    if (response && response.error) {
      if (response.data && response.data.error) {
        notification.error({ message: response.data.error })
      } else if (typeof response.error === 'string') {
        notification.error({ message: response.error })
      } else {
        notification.error({ message: 'An unknown error has occurred' })
      }
    }

    return response ? response : null
  }
})

api.interceptors.request.use(
  config => {
    const token = getToken()

    if (token) {
      config.headers.Authorization = token
    }

    return config
  },
  error => Promise.reject(error)
)

export default api