/* eslint-disable indent */

const INITIAL_STATE = []

const Trades = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_TRADES_DATA':
      return action.Trades
    default:
      return state
  }
}

export default Trades
