import React from 'react'
import { Card, Skeleton } from 'antd'

const Module = ({ title, loading, children, bordered, className, noPadding }) => {
  return (
    <Card className={'module-card' + (bordered ? ' no-margin' : '') + ` ${className}`} bordered={bordered ? true : false}>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {title}
          <div className={noPadding ? 'no-padding' : ''}>
            {children}
          </div>
        </>
      )}
    </Card>
  )
}

export default Module
