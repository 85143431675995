/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { Layout, Button, Form, notification, Typography, Table, Modal, Input, Popconfirm, Select } from 'antd'
import Module from '../../../Components/Module'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../../Services/api'

const { Title } = Typography
const { Option } = Select

const useForceUpdate = () => {
  const [, setIt] = useState(false)
  return () => setIt(it => !it)
}

const UsersSettingsPage = () => {

  const forceUpdate = useForceUpdate()

  const [users, setUsers] = useState([])

  // CREATE USERS
  const [createModal, setCreateModal] = useState(false)
  const [createData, setCreateData] = useState({})
  const [creating, setCreating] = useState(false)

  // EDIT USERS
  const [editModal, setEditModal] = useState(false)
  const [editting, setEditting] = useState(false)

  const dispatch = useDispatch()
  const editData = useSelector(state => state.EditingUser)
  
  const handleEditChange = ({ name, value }) => {
    const EditingUser = editData
    EditingUser[name] = value
    dispatch({ type: 'CHANGE_EDITING_USER_DATA', EditingUser })

    forceUpdate()
  }

  const setEditData = (data) => {
    dispatch({ type: 'CHANGE_EDITING_USER_DATA', EditingUser: data })
  }

  const handleEdit = () => {
    setEditting(true)

    api.put('/user', editData)
      .then(() => {
        getData()
        notification.success({
          message: 'User updated successfully'
        })
      })
      .finally(() => {
        setEditModal(false)
        setEditData({ })
        setEditting(false)
      })
  }

  const getData = () => {
    api.get('/users')
      .then(response => response.data)
      .then(setUsers)
  }

  const handleDelete = (id) => {
    api.delete(`/user/${id}`)
      .then(() => {
        notification.success({
          message: 'User deleted succesfully'
        })
        getData()
      })
  }
  
  const handleEditModal = (userData) => {
    setEditData({...userData})
    setEditModal(true)
  }

  const handleCreate = () => {
    setCreating(true)
    api.post('/user', createData)
      .then(() => {
        getData()
        notification.success({
          message: 'User created successfully'
        })
      })
      .finally(() => {
        setCreateModal(false)
        setCreating(false)
      })
  }

  const handleCreateChange = ({ name, value }) => {
    const data = createData

    data[name] = value

    setCreateData(data)
  }

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Paxful username',
      dataIndex: 'pax_username',
      key: 'pax_username'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_text, record) => (
        <span>
          <a onClick={() => handleEditModal(record)} style={{ marginRight: 16 }}>Edit</a>
          <Popconfirm 
            title='Are you sure you want to delete this user?'
            onConfirm={() => handleDelete(record._id)}
            okText='Yes'
            cancelText='No'
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ]

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout>
      <Module>
        <Modal
          title='Create user'
          visible={createModal}
          onOk={handleCreate}
          onCancel={() => setCreateModal(false)}
          okButtonProps={{
            loading: creating
          }}>
          <Form autoComplete='new-password'>
            <Form.Item label='Username'>
              <Input onChange={(e) => handleCreateChange({ name: 'username', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Full name'>
              <Input onChange={(e) => handleCreateChange({ name: 'fullname', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Role'>
              <Select defaultValue='employee' onChange={(value) => handleCreateChange({ name: 'role', value })}>
                <Option value='admin'>Admin</Option>
                <Option value='employee'>Employee</Option>
              </Select>
            </Form.Item>
            <Form.Item label='Paxful account username (case sensitive)'>
              <Input autoComplete='new-password' onChange={(e) => handleCreateChange({ name: 'pax_username', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Password'>
              <Input autoComplete='new-password' type='password' onChange={(e) => handleCreateChange({ name: 'password', value: e.target.value })} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title='Edit user'
          visible={editModal}
          onOk={handleEdit}
          onCancel={() => setEditModal(false)}
          okButtonProps={{
            loading: editting
          }}>
          <Form>
            <Form.Item label='Username'>
              <Input value={editData.username} disabled />
            </Form.Item>
            <Form.Item label='Full name'>
              <Input value={editData.fullname} onChange={(e) => handleEditChange({ name: 'fullname', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Role'>
              <Select value={editData.role} onChange={(value) => handleEditChange({ name: 'role', value })}>
                <Option value='admin'>Admin</Option>
                <Option value='employee'>Employee</Option>
              </Select>
            </Form.Item>
            <Form.Item label='Paxful account username (case sensitive)'>
              <Input autoComplete='new-password' value={editData.pax_username} onChange={(e) => handleEditChange({ name: 'pax_username', value: e.target.value })} />
            </Form.Item>
            <Form.Item label='Password'>
              <Input autoComplete='new-password' type='password' onChange={(e) => handleEditChange({ name: 'password', value: e.target.value })} />
            </Form.Item>
          </Form>
        </Modal>

        <Title level={4}>Actions</Title>

        <Button onClick={() => setCreateModal(true)} type='primary'>Create user</Button> 
      </Module>

      <Module>
        <Table bodyStyle={{backgroundColor: '#ececec'}} columns={columns} dataSource={users} loading={users.length === 0} />
      </Module>
    </Layout>
  )
}

export default UsersSettingsPage