/* eslint-disable indent */
const INITIAL_STATE = {
  
}

const editingUser = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_EDITING_USER_DATA':
      return action.EditingUser
    default:
      return state
  }
}

export default editingUser
