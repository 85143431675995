import React, { useState } from 'react'
import { Layout, Form, Typography, Input, Row, Col, Button } from 'antd'
import Module from '../../../Components/Module'
import api from '../../../Services/api'

const { Title } = Typography

const PrepaidPage = () => {

  const [formData, setFormData] = useState({ })

  const handleSubmit = async () => {
    try {
      const response = await api.post('/prepaid-cards/process', formData)

      console.log(response)
    } catch(e) {
      console.log(e)
    }
  }

  const handleChange = (e) => {
    
  }

  const formLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 }
  }

  return (
    <Layout>
      <Module title={<Title level={4}>Prepaid cards processing</Title>}>
        <Form  {...formLayout}>

          <Module bordered={true} title={<Title level={4}>Card details</Title>}>
            <Form.Item label='Card number'>
              <Input type='text' placeholder='XXXX XXXX XXXX XXXX'></Input>
            </Form.Item>
            <Form.Item label='Expiration date'>
              <Row>
                <Col md={11}>
                  <Input type='number' min={1} max={12} placeholder='Month'></Input>
                </Col>
                <Col offset={2} md={11}>
                  <Input type='number' min={new Date().getFullYear()} max={5000} placeholder='Year'></Input>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label='CVV'>
              <Input type='text' placeholder='Enter CVV here (XXX)'></Input>
            </Form.Item>
            <Form.Item label='Name on card'>
              <Input type='text' placeholder='Enter name on card here'></Input>
            </Form.Item>
          </Module>

          <Module bordered={true} title={<Title level={4}>Customer details</Title>}>
            <Form.Item label='First name'>
              <Input type='text' placeholder='Enter name here'></Input>
            </Form.Item>
            <Form.Item label='Last name'>
              <Input type='text' placeholder='Enter name here'></Input>
            </Form.Item>
            <Form.Item label='Birthday'>
              <Input type='text' placeholder='Enter birthday here'></Input>
            </Form.Item>
            <Form.Item label='E-mail'>
              <Input type='email' placeholder='Enter e-mail here'></Input>
            </Form.Item>
          </Module>
          
          <Module bordered={true} title={<Title level={4}>Billing details</Title>}>
            <Form.Item label='Country'>
              <Input type='text' placeholder='Enter country here'></Input>
            </Form.Item>
            <Form.Item label='Address 1'>
              <Input type='text' placeholder='Enter billing address 1 here'></Input>
            </Form.Item>
            <Form.Item label='Address 2'>
              <Input type='text' placeholder='Enter billing address 2 here'></Input>
            </Form.Item>
            <Form.Item label='City'>
              <Input type='text' placeholder='Enter billing city here'></Input>
            </Form.Item>
            <Form.Item label='Zip code'>
              <Input type='text' placeholder='Enter billing zip code here'></Input>
            </Form.Item>
          </Module>

          <Module bordered={true} title={<Title level={4}>Trade details</Title>}>
            <Form.Item label='Amount'>
              <Input type='number' min={1} placeholder='Enter amount here ($ 0.00)'></Input>
            </Form.Item>
            <Form.Item label='Currency'>
              <Input type='text' placeholder='Enter currency code here (USD)'></Input>
            </Form.Item>
          </Module>
          
          <Button type='primary' onClick={handleSubmit}>Submit</Button>
        </Form>
      </Module>
    </Layout>
  )
}

export default PrepaidPage