import store from '../Store'
import api from './api'

const setReports = data => {
  store.dispatch({ type: 'CHANGE_REPORTS_DATA', Reports: data })
}

const backgroundWorker = {
  updating: false,
  update: () => {
    api.get('/reports')
      .then(response => response.data)
      .then(setReports)
  },
  run: () => {
    backgroundWorker.update()
  },
  start: () => {
    backgroundWorker.update()
    setInterval(backgroundWorker.run, 10000)
  },
  stop: () => {
    clearInterval(backgroundWorker.updating)
  }
}

export default backgroundWorker