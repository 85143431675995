import React from 'react'
import { Row, Col, Layout, Typography, Divider } from 'antd'
import Module from '../../Components/Module'
import { useSelector } from 'react-redux'
import Chart from 'react-apexcharts'

const { Title } = Typography

Number.prototype.toCurrencyString = function () {
  return this.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

String.prototype.toCurrencyString = function () {
  return Number.parseFloat(this).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const DashboardPage = () => {
  const reports = useSelector(state => state.Reports)

  const getReport = (name) => {
    if (!reports) return false

    const report = reports.find(report => report.name === name) 

    return report ? report.value : false
  }

  return (
    <Layout>
      <Row>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Completed trades' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').yearly.amount.toCurrencyString()}
            </Title>
            <p>Profit {getReport('stats') && getReport('stats').yearly.profit.toCurrencyString()}</p>
            <p>Margin {getReport('stats') && getReport('stats').yearly.average_margin.toFixed(2)}%</p>
            <p>Total {getReport('stats') && getReport('stats').yearly.total}</p>
            <Divider />
            <span>Yearly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Completed trades' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').monthly.amount.toCurrencyString()}
            </Title>
            <p>Profit {getReport('stats') && getReport('stats').monthly.profit.toCurrencyString()}</p>
            <p>Margin {getReport('stats') && getReport('stats').monthly.average_margin.toFixed(2)}%</p>
            <p>Total {getReport('stats') && getReport('stats').monthly.total}</p>
            <Divider />
            <span>Monthly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Completed trades' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').weekly.amount.toCurrencyString()}
            </Title>
            <p>Profit {getReport('stats') && getReport('stats').weekly.profit.toCurrencyString()}</p>
            <p>Margin {getReport('stats') && getReport('stats').weekly.average_margin.toFixed(2)}%</p>
            <p>Total {getReport('stats') && getReport('stats').weekly.total}</p>
            <Divider />
            <span>Weekly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Completed trades' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').daily.amount.toCurrencyString()}
            </Title>
            <p>Profit {getReport('stats') && getReport('stats').daily.profit.toCurrencyString()}</p>
            <p>Margin {getReport('stats') && getReport('stats').daily.average_margin.toFixed(2)}%</p>
            <p>Total {getReport('stats') && getReport('stats').daily.total}</p>
            <Divider />
            <span>Daily</span>
          </Module>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Module title='Monthly overview' loading={!getReport('monthly_days')}>
            <Chart
              options={{
                chart: {
                  id: 'basic-bar'
                },
                xaxis: {
                  categories: getReport('monthly_days')[0]
                },
                colors: ['#53c93b', '#b93690']
              }}
              series={[{
                name: 'Volume (USD)',
                data: getReport('monthly_days')[1]
              }, {
                name: 'Profit (USD)',
                data: getReport('monthly_days')[2]
              }]}
              type="line"
              height={300}
            />
          </Module>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Average profit' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').yearly.average_profit.toCurrencyString()}
            </Title>
            Average amount
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').yearly.average_amount.toCurrencyString()}
            </Title>
            <Divider />
            <span>Yearly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Average profit' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').monthly.average_profit.toCurrencyString()}
            </Title>
            Average amount
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').monthly.average_amount.toCurrencyString()}
            </Title>
            <Divider />
            <span>Monthly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Average profit' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').weekly.average_profit.toCurrencyString()}
            </Title>
            Average amount
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').weekly.average_amount.toCurrencyString()}
            </Title>
            <Divider />
            <span>Weekly</span>
          </Module>
        </Col>
        <Col md={6}>
          <Module loading={!getReport('stats')} title='Average profit' >
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').daily.average_profit.toCurrencyString()}
            </Title>
            Average amount
            <Title className='green' level={2}>
              $ {getReport('stats') && getReport('stats').daily.average_amount.toCurrencyString()}
            </Title>
            <Divider />
            <span>Daily</span>
          </Module>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Module title='Activity heatmap' loading={!getReport('heatmap')}>
            <Chart
              options={{
                chart: {
                  id: 'heatmap'
                },
                plotOptions: {
                  heatmap: {
                    colorScale: {
                      ranges: [{
                        from: -1,
                        to: 5,
                        color: '#1c7ed6',
                        name: 'Low'
                      }, {
                        from: 6,
                        to: 12,
                        color: '#53c93b',
                        name: 'High'
                      }, {
                        from: 13,
                        to: 25,
                        color: '#b93690',
                        name: 'Extreme'
                      }]
                    }
                  }
                },
                xaxis: {
                  categories: getReport('heatmap').y
                },
                dataLabels: {
                  enabled: false
                }
              }}
              type="heatmap"
              height={600}
              series={getReport('heatmap').x}
            />
          </Module>
        </Col>
      </Row>
    </Layout>
  )
}

export default DashboardPage
