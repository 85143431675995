const INITIAL_STATE = []

const reports = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_REPORTS_DATA':
      return action.Reports
    default:
      return state
  }
}

export default reports
